import React from "react"
import styled from "@emotion/styled"
import { a, useSpring } from "react-spring"
import { useInView } from "react-intersection-observer"
import { useSiteMetadata } from "helpers/hooks"
import { mqTabletHandheld } from "components/index.css"
import { H1, H2, H3, P, Section } from "components/typography"
import SEO from "../components/seo"
import S from "components/sticker"
import Disclose from "components/disclose"
import Quote from "components/quote"

const triggerOnce = true

const ServicesPage = () => {
  const { email } = useSiteMetadata()
  const [firstRef, firstInView] = useInView({ triggerOnce })
  const [secondRef, secondInView] = useInView({ triggerOnce })
  const [thirdRef, thirdInView] = useInView({ triggerOnce })
  const [fourthRef, fourthInView] = useInView({ triggerOnce })
  const [fifthRef, fifthInView] = useInView({ triggerOnce })
  const [stickerRef, stickerInView] = useInView({ triggerOnce, threshold: 0.5 })

  const firstProps = useSpring({
    opacity: firstInView ? 1 : 0,
    transform: firstInView ? `translateX(0) ` : ` translateX(-20%)`,
  })

  const secondProps = useSpring({
    opacity: secondInView ? 1 : 0,
    transform: secondInView ? `translateX(0) ` : ` translateX(20%)`,
  })

  const thirdProps = useSpring({
    opacity: thirdInView ? 1 : 0,
    transform: thirdInView ? `translateX(0) ` : ` translateX(-20%)`,
  })

  const fourthProps = useSpring({
    opacity: fourthInView ? 1 : 0,
    transform: fourthInView ? `translateX(0) ` : ` translateX(20%)`,
  })

  const fifthProps = useSpring({
    opacity: fifthInView ? 1 : 0,
    transform: fifthInView ? `translateX(0) ` : ` translateX(-20%)`,
  })

  function handleMail(e) {
    e.preventDefault()
    const subject = encodeURIComponent(`Planes de Igualdad`)
    const body = encodeURIComponent(
      `Muy buenas,\n\nQuerría obtener información acerca de las fecha límite de implementación de planes de igualdad que afectan a mi empresa.\n\nNombre de mi empresa: (escribe aquí)\n\nNúmero de empleados: (escribe aquí)\n\nMuchas gracias.\n\nUn saludo.`
    )
    window.location.href = `mailto:${email}?subject=${subject}&body=${body}`
  }

  return (
    <>
      <SEO title="Servicios" />
      <H1>servicios</H1>
      <Section>
        <Copy style={firstProps}>
          <H2>Compliance Penal</H2>
          <H3 ref={firstRef}>
            Identificar, clasificar y prevenir riesgos penales. Nuestro objetivo
            es evitar la comisi&oacute;n de delitos dentro de tu empresa.
          </H3>
          <Disclose>
            <P>
              Analizamos tu empresa para identificar y evaluar los riesgos
              existentes y el impacto (econ&oacute;mico y reputacional) que los
              mismos pueden ocasionar.
            </P>
            <P>
              Nuestros planes de cumplimiento est&aacute;n estructurados en una
              sucesi&oacute;n de servicios basados en prevenci&oacute;n,
              gesti&oacute;n, supervisi&oacute;n y control de todos los aspectos
              del cumplimiento normativo que requiere la persona jur&iacute;dica
              para alcanzar el m&aacute;ximo desarrollo de tu actividad en tus
              diferentes departamentos. Debemos hacerlo con el menor riesgo
              posible de transgredir la legislaci&oacute;n vigente.
            </P>
            <P>
              Nos encargamos de revisar y actualizar los planes de cumplimiento
              tal y como exigen las UNE/ISO 19600 -19601. La legislaci&oacute;n
              actual sobre cumplimiento da especial importancia al mantenimiento
              y actualizaci&oacute;n de los planes implantados.
            </P>
            <P>
              Nuestro programa de Compliance incluye la gesti&oacute;n de un
              Canal de Denuncias externo. Estos canales son herramientas que
              permiten a empleados u otras personas alertar confidencialmente a
              tu empresa sobre sospechas de mala conducta. Las Directiva de la
              Uni&oacute;n Europea exige a las empresas que tengan m&aacute;s de
              50 trabajadores o con una facturaci&oacute;n mayor a 10 millones
              de euros implantar un canal de denuncias.
            </P>
            <P>
              Estos procedimientos dan gran valor a la redacci&oacute;n de un
              C&oacute;digo &Eacute;tico o C&oacute;digo de Conducta. Se trata
              de un documento que recoge un conjunto de principios y criterios,
              normas y valores que forman la ideolog&iacute;a de la empresa,
              siendo asumidos por todo el personal que trabaja en ella.
            </P>
            <P>
              Incorporamos a tus planes de cumplimiento la formaci&oacute;n
              espec&iacute;fica de todos los miembros de tu empresa; con el
              objetivo de dar a conocer y formar a los trabajadores en el
              estricto cumplimiento normativo.
            </P>
          </Disclose>
        </Copy>
      </Section>
      <Section marginTop={0}>
        <StickerWrapper ref={stickerRef}>
          <Sticker side="left" inView={stickerInView}>
            <P>&amp;</P>
            <P>si&eacute;ntete</P>
            <P>seguro</P>
          </Sticker>
        </StickerWrapper>
        <Copy style={secondProps}>
          <H2>Corporate Compliance</H2>
          <H3 ref={secondRef}>
            Control, seguimiento y actualizaci&oacute;n de todos los procesos
            para que tu empresa cumpla con la normativa y legislaci&oacute;n
            vigente.
          </H3>
          <Disclose>
            <P>
              Tu Empresa puede incurrir en otros incumplimientos normativos
              (adem&aacute;s de los riesgos penales) Nuestros planes de
              cumplimiento abarcan tambi&eacute;n las responsabilidades de tipo
              administrativo, laboral, civil o tributario, que llevan aparejadas
              sanciones de gran impacto.
            </P>
            <P>
              Implantamos los procesos necesarios para evitar la Responsabilidad
              Societaria de los Administradores. Nos encargarmos de implementar
              la cultura de corporate compliance en tu Empresa y en tu entorno
              (proveedores, socios, etc.)
            </P>
          </Disclose>
        </Copy>
      </Section>
      <Quote>
        <p>
          &ldquo;Implantamos los procesos necesarios para evitar la
          Responsabilidad Societaria de los Administradores. Nos encargarmos de
          implementar la cultura de corporate compliance en tu Empresa y en tu
          entorno (proveedores, socios, etc.)&rdquo;
        </p>
        <p>
          Carmen Tarr&oacute;n
          <br />
          Socia Directora
        </p>
      </Quote>
      <Section>
        <Copy style={thirdProps}>
          <H2>Blanqueo de Capitales</H2>
          <H3 ref={thirdRef}>
            Tu empresa necesita diversos mecanismos de vigilancia y alarmas para
            evitar el blanqueo de capitales.
          </H3>
          <Disclose>
            <P>
              Hemos dise&ntilde;ado un completo servicio de consultor&iacute;a
              en materia de prevenci&oacute;n blanqueo de capitales para tu
              total tranquilidad.
            </P>
            <P>
              Implantamos en tu empresa una serie de pol&iacute;ticas y procesos
              dirigidos a cumplir con la ley 10/2010, de 28 de abril, de
              prevenci&oacute;n de blanqueo de capitales y financiaci&oacute;n
              del terrorismo, de manera &aacute;gil, sencilla, eficaz y con un
              importante ahorro de costes.
            </P>
          </Disclose>
        </Copy>
      </Section>
      <Section style={{ justifyContent: `flex-end` }}>
        <Copy style={fourthProps}>
          <div />
          <H2>Planes de Igualdad</H2>
          <H3 ref={fourthRef}>
            Conjunto de acciones y protocolos para garantizar la igualdad de
            trato y oportunidades entre mujeres y hombres en el &aacute;mbito
            laboral.
          </H3>
          <Disclose>
            <P>
              Elaboramos e implantamos el plan de igualdad obligatorio para tu
              empresa.
            </P>

            <P>
              Gestionamos la inscripci&oacute;n del mismo en el Registro
              correspondiente.
            </P>

            <P>
              Los planes de igualdad son obligatorios en empresas que cuenten
              con m&aacute;s de 50 trabajadores.
            </P>

            <P>
              La fecha de inicio para la implantaci&oacute;n de los planes
              comenz&oacute; el 7 de marzo del 2.019.
            </P>
            <A href="/#" onClick={handleMail}>
              Puedes consultar sin compromiso la fecha l&iacute;mite de
              implementaci&oacute;n que afecta a tu empresa para evitar posibles
              sanciones en este enlace.
            </A>
          </Disclose>
        </Copy>
      </Section>
      <Section>
        <Copy style={fifthProps}>
          <H2>Ciberseguridad y Nuevas Tecnolog&iacute;as</H2>
          <H3 ref={fifthRef}>
            Protecci&oacute;n de la infraestructura inform&aacute;tica y todo lo
            relacionado con &eacute;sta.
          </H3>
          <Disclose>
            <P>
              Los derechos digitales tienen cada d&iacute;a m&aacute;s
              relevancia y las empresas{" "}
              <span>
                tenemos la obligaci&oacute;n por nosotros y por nuestros
                clientes de estar bien asesorados
              </span>{" "}
              en nuevas tecnolog&iacute;as.
            </P>

            <P>
              Conocer y emplear buenas pr&aacute;cticas en redes sociales,
              email, website, y dem&aacute;s entornos online evitar&aacute;
              posibles amenazas e incidentes de seguridad que, de suceder,
              pueden suponer grandes y graves p&eacute;rdidas econ&oacute;micas
              para los negocios, robo de datos sensibles (personales o de
              negocio) y, en definitiva, p&eacute;rdida de la confianza de los
              clientes y quebranto de la reputaci&oacute;n, que finalmente se
              traducir&aacute;n en disminuci&oacute;n de resultados o
              desaparici&oacute;n del negocio, incluso posibles problemas
              legales por da&ntilde;os o por p&eacute;rdidas econ&oacute;micas.
            </P>

            <P>
              Nuestros servicios abarcan la legalizaci&oacute;n web, comercio
              electr&oacute;nico, reputaci&oacute;n online, derecho y marketing,
              derecho media &amp; entertainment y auditor&iacute;as legales de
              tu sitio web.
            </P>
          </Disclose>
        </Copy>
      </Section>
    </>
  )
}

export default ServicesPage

const Copy = styled(a.div)({
  flex: `1 1 70%`,
  maxWidth: 650,
  [mqTabletHandheld]: {
    flex: `1 0 100%`,
  },
})

const Sticker = styled(S)(({ side }) => ({
  background: `rgba(0, 67, 255, 1)`,
  marginLeft: 50,
}))

const StickerWrapper = styled.div({
  display: `flex`,
  flex: 1,
  paddingBottom: 20,
})

const A = styled(P)({
  textDecoration: `underline`,
}).withComponent("a")
