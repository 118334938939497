import React, { useState } from "react"
import styled from "@emotion/styled"
import { a, config, useSpring } from "react-spring"
import { useMeasure } from "helpers/hooks"
import { mqHandheld, mqTablet } from "components/index.css"

const Disclose = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [bind, { height }] = useMeasure()

  const springProps = useSpring({
    config: config.slow,
    height: isOpen ? height : 0,
    opacity: isOpen ? 1 : 0,
    transform: `translate3d(0, ${isOpen ? 0 : -40}px, 0)`,
    margin: isOpen ? `30px 0` : `0px 0`,
  })

  function compensateScroll() {
    if (!isOpen) return
    window.scrollTo({
      top: window.pageYOffset - height,
      behavior: `smooth`,
    })
  }

  function toggle() {
    compensateScroll()
    setIsOpen(!isOpen)
  }

  return (
    <>
      <Wrapper style={{ overflow: `hidden`, ...springProps }}>
        <Copy {...bind}>{children}</Copy>
      </Wrapper>
      <Container isOpen={isOpen}>
        <ButtonWrapper>
          <Button type="button" onClick={toggle}>
            <span>{isOpen ? `-` : `+`}</span>
          </Button>
        </ButtonWrapper>
      </Container>
    </>
  )
}

export default Disclose

const Wrapper = styled(a.div)({
  overflow: `hidden`,

  [mqHandheld]: {},
})

const Container = styled.div(({ isOpen }) => ({
  display: `flex`,
  justifyContent: `flex-end`,
  padding: 5,

  [mqTablet]: {
    marginTop: 22,
  },
  [mqHandheld]: {
    marginBottom: isOpen ? 25 : 0,
  },
}))

const ButtonWrapper = styled.div({
  width: 25,
  height: 25,
  borderRadius: `50%`,
  display: `flex`,
  justifyContent: `center`,
  alignItems: `center`,
  background: `rgba(235, 195, 46, 1)`,

  [mqTablet]: {
    width: 32,
    height: 32,
  },
  [mqHandheld]: {
    width: 23,
    height: 23,
  },
})

const Button = styled.button({
  width: `100%`,
  height: `100%`,
  display: `flex`,
  justifyContent: `center`,
  alignItems: `center`,
  overflow: `hidden`,
  border: `none`,
  background: `transparent`,
  fontFamily: `Recoleta`,
  fontSize: 25,
  lineHeight: `1px`,
  fontWeight: `bolder`,
  "&:hover": {
    cursor: `pointer`,
  },

  [mqHandheld]: {
    fontSize: 21,
    lineHeight: `21px`,
  },
})

const Copy = styled.div({
  "> a, p": {
    fontFamily: `Graphik`,
    fontSize: 16,
    lineHeight: `20px`,
    marginBottom: 30,

    [mqTablet]: {
      fontSize: 20,
      lineHeight: `29px`,
    },
    [mqHandheld]: {
      fontSize: 15,
      lineHeight: `23px`,
    },
  },
  "> a": {
    lineHeight: `22px`,
  },
})
